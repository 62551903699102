@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    font-size: 20px;
    font-weight: bold;
    user-select: none;
    overflow: hidden;
    font-family: 'Montserrat';
}

html, body {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.button {
    background-color: #e89800ff;
    width: 100%;
    border-radius: 5px;
    color: #f2f2f2ff;
    padding-top: 15px;
    padding-bottom: 15px;
    transform: scale(1);
    text-align: center;
}

#progress {
    transition: width 300ms;
}

.button:hover {
    cursor: pointer;
    background-color: #d38900ff;
    transform: scale(1.02);
}

div {
    max-width: 100vw;
}
